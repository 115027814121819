<template>
  <div>
    <div class="flex flex-row w-full py-2 pl-1 gap-1 items-center">
      <div class="flex w-100 items-center mr-2">
        <div class="competency-label rounded-full ml-1.5 mr-4" :style="{ 'background-color': `${source.color}` }"></div>
        <p class="font-normal text-md text-grey-dark-2 w-auto">
          {{ index + 1 }}
        </p>
      </div>
      <div class="flex flex-grow w-1/4 mr-1">
        <p class="flex flex-grow text-md text-grey-dark-1 font-semibold ml-0.2">
          {{ source.name.replaceAll("&nbsp;", " ") }}
        </p>
      </div>
      <div class="flex flex-grow w-2/5">
        <p class="text-md text-grey-dark-2 ml-0.2">
          <ExpandMore :data="subComps" showHideButton />
        </p>
      </div>
      <div class="flex flex-grow w-1/5 justify-center items-center">
        <p class="text-md text-grey-dark-2" :class="isActive ? 'text-green' : 'text-red'">
          {{ !!isActive ? "Active" : "Inactive" }}
        </p>
      </div>
      <div class="flex flex-grow w-160 ml-auto">
        <IconButton v-if="isReadOnly" :icon="view" :onClick="() => editCompetency(source.id)"
          class="iconButton border ml-2 border-grey-border" />
        <div v-else class="flex gap-1">
          <IconButton :icon="edit" :onClick="() => editCompetency(source.id)"
            class="iconButton border border-grey-border" />
          <IconButton :icon="remove" :onClick="() => removeCompetency(source.id)"
            class="iconButton border border-grey-border" />
        </div>
      </div>
    </div>
    <div class="border border-grey-light-1 border-top-none" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import remove from "@/assets/img/icons/remove.svg";
import edit from "@/assets/img/icons/edit.svg";
import view from "@/assets/img/icons/view.svg";

import ExpandMore from "../../../../components/ExpandMore/ExpandMore.vue";
import IconButton from "../../../../components/IconButton/IconButton.vue";

export default {
  components: { ExpandMore, IconButton },
  name: "ListItem",
  data: () => ({ remove, edit, view }),
  props: {
    index: { type: Number },
    source: { type: Object, default: () => ({}) },
    editCompetency: { type: Function, default: () => { } },
    removeCompetency: { type: Function, default: () => { } },
  },
  computed: {
    ...mapGetters({
      isReadOnly: "products/isReadOnly",
    }),
    subComps() {
      return this.source?.subCompetencies?.map((item) => item.name);
    },
    surveys() {
      return this.source?.survey;
    },
    isActive(){
      return this.source?.active;
    }
  },
};
</script>
<style lang="scss">
.competency-label {
  min-width: 13px;
  height: 13px;
  width: 13px;
}

.iconButton {
  width: 30px !important;
  height: 30px !important;
}
</style>
