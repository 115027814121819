<template>
  <div>
    <div class="flex flex-row w-full py-2 pl-1.5 items-center">
      <div class="flex w-75 justify-center pr-1">
        <p class="font-normal text-md text-grey-dark-2">
          {{ index + 1 }}
        </p>
      </div>
      <div class="flex flex-grow w-1/4 pl-0.5">
        <p class="flex flex-grow text-md text-grey-dark-1 font-semibold">
          {{ source.name }}
        </p>
      </div>
      <div class="flex flex-grow w-1/3 pl-0.5">
        <p class="text-md text-grey-dark-2">
          <ExpandMore :data="jobTitles" showHideButton/>
        </p>
      </div>
      <div class="flex flex-grow w-1/20 -mr-1 justify-center items-center">
        <p
          class="text-md text-grey-dark-2"
          :class="source.isActive ? 'text-green' : 'text-red'"
        >
          {{ !!source.isActive ? "Active" : "Inactive" }}
        </p>
      </div>
      <div
        class="flex flex-grow flex-col w-1/5 justify-center items-center mr-1"
      >
        <p class="text-md text-grey-dark-2">
          {{ targetsSet }}
        </p>
      </div>
      <div class="flex flex-grow w-100 ml-auto">
        <IconButton
          v-if="isReadOnly"
          :icon="view"
          :onClick="() => editJobRole(source.id)"
          class="iconButton border ml-2 border-grey-border"
        />
        <div v-else class="flex gap-1 w-100">
          <IconButton
            :icon="edit"
            :onClick="() => editJobRole(source.id)"
            class="iconButton border border-grey-border"
          />
          <IconButton
            :icon="remove"
            :onClick="() => removeJobRole(source.id)"
            class="iconButton border border-grey-border"
          />
        </div>
      </div>
    </div>
    <div class="border border-grey-light-1 border-top-none" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import remove from "@/assets/img/icons/remove.svg";
import edit from "@/assets/img/icons/edit.svg";
import view from "@/assets/img/icons/view.svg";

import ExpandMore from "../../../../components/ExpandMore/ExpandMore.vue";
import IconButton from "../../../../components/IconButton/IconButton.vue";

export default {
  name: "ListItem",
  components: { IconButton, ExpandMore },
  data: () => ({ remove, edit, view }),
  props: {
    index: { type: Number },
    source: { type: Object, default: () => ({}) },
    editJobRole: { type: Function, default: () => {} },
    removeJobRole: { type: Function, default: () => {} },
  },
  computed: {
    ...mapGetters({
      isReadOnly: "products/isReadOnly",
    }),
    jobTitles() {
      return this.source.jobTitles.split(",");
    },
    targetsSet() {
      return this.source.targetsSet;
    },
  },
};
</script>
<style lang="scss">
.iconButton {
  width: 30px !important;
  height: 30px !important;
}
</style>
