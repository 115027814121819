<template>
  <div>
    <div
      v-if="!hasCompetencies"
      class="mb-2 bg-orange h-3.5 w-full flex gap-1 px-1 items-center rounded"
    >
      <img :src="warning" />
      <p class="font-medium text-sm text-white">
        In order to create assessment components, you need to create core
        competencies first.
      </p>
    </div>
    <div class="bg-white p-2 relative mb-2">
      <div
        v-if="!hasCompetencies"
        class="absolute inset-0 opacity-50 bg-white"
      />
      <Button
        v-if="hideSurveyDetails && !isReadOnly"
        text="Create survey"
        type="primary"
        size="medium"
        class="absolute top-3 right-2"
        @click.native="createSurvey"
        :disabled="!hasCompetencies"
      />
      <div class="max-w-3/5">
        <p class="font-headers text-mdl font-bold text-grey-light mt-1 mb-1">
          Survey
        </p>
        <div class="ml-2">
          <p class="font-normal text-grey-dark-1 text-mdh mb-2">
            Create a survey for each assessment, containing questions and answer
            types. They can be used in order to create self- assessments,
            organizational reviews or both.
          </p>
          <p
            v-if="hideSurveyDetails"
            class="text-mdlh text-grey-dark-1 font-medium mb-1"
          >
            No survey created yet.
          </p>
          <div
            v-else
            class="w-full h-100 bg-blue-light-1 rounded-md flex py-2 px-3"
          >
            <div class="w-full">
              <p class="text-grey-dark-1 text-mdh font-semibold">
                {{ isIndividual ? "Individual" : "Organizational" }} Assessment
                Survey
              </p>
              <p class="text-grey-light text-sm-2-h font-normal">
                {{ productDetails.questionsCount }} Questions
              </p>
            </div>
            <IconButton
              v-if="isReadOnly"
              :icon="view"
              :onClick="() => editSurvey(productDetails.id)"
              class="iconButton self-center border border-grey-border"
            />
            <div v-else class="flex gap-1 ml-auto items-center">
              <IconButton
                :icon="edit"
                :onClick="() => editSurvey(productDetails.id)"
                class="iconButton border border-grey-border"
              />
              <IconButton
                :icon="remove"
                :onClick="() => removeSurvey(productDetails.id)"
                class="iconButton border border-grey-border"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import warning from "@/assets/img/icons/warningWhite.svg";
import remove from "@/assets/img/icons/remove.svg";
import edit from "@/assets/img/icons/edit.svg";
import view from "@/assets/img/icons/view.svg";

import IconButton from "../../../components/IconButton/IconButton.vue";

export default {
  name: "SurveyCard",
  components: { IconButton },
  data: () => ({ warning, remove, edit, view }),
  computed: {
    ...mapState({
      productDetails: (state) => state.products.productDetails,
    }),
    ...mapGetters({
      isReadOnly: "products/isReadOnly",
    }),
    hasCompetencies() {
      return !!this.productDetails?.coreCompetencies?.length;
    },
    hideSurveyDetails() {
      return !(this.productDetails.scoresSet && this.hasCompetencies);
    },
    isIndividual() {
      return this.productDetails.surveyType === 0;
    },
  },
  methods: {
    ...mapActions({
      resetScores: "products/resetScores",
      getScoreSettings: "products/getScoreSettings",
      openModal: "modals/openModal",
    }),
    createSurvey() {
      this.resetScores();
      return this.$router.push("scores");
    },
    async editSurvey(id) {
      await this.getScoreSettings(id).then(() => this.$router.push("scores"));
    },
    removeSurvey(id) {
      this.openModal({
        modal: "deleteSurveyModal",
        props: { id },
      });
    },
  },
};
</script>
