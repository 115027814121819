<template>
  <div class="bg-white p-2 relative mb-2">
    <Button
      v-if="!isReadOnly"
      text="Add Job Roles"
      type="primary"
      :iconLeft="add"
      @click.native="addJobRoles"
      size="medium"
      class="absolute top-3 right-2"
    />
    <div class="max-w-3/5">
      <p class="font-headers text-mdl font-bold text-grey-light mt-1 mb-1">
        Job Roles
      </p>
      <div class="ml-2">
        <p class="font-normal text-grey-dark-1 text-mdh mb-2">
          Job Roles are categories of responsibilities a person needs to hold
          while being assessed for the competencies to accomplish that certain
          job. A paticipant can hold a single job role per assessment.
        </p>
      </div>
    </div>
    <div class="ml-2 mr-4.5">
      <p
        v-if="!hasJobRoles"
        class="text-mdlh text-grey-dark-1 font-medium mb-1"
      >
        No job roles added yet.
      </p>
      <div v-else>
        <p class="font-normal text-grey-dark-1 text-mdh mb-3">
          {{ jobRolesCount }} Total Job Roles
        </p>
        <ProductJobRoles />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import add from "@/assets/img/icons/add.svg";

import ProductJobRoles from "../components/ProductJobRoles";

export default {
  name: "JobeRolesCard",
  components: { ProductJobRoles },
  data: () => ({ add }),
  computed: {
    ...mapState({
      productDetails: (state) => state.products.productDetails,
    }),
    ...mapGetters({
      isReadOnly: "products/isReadOnly",
    }),
    jobRolesCount() {
      return this.productDetails.allowedJobRoles.length;
    },
    hasJobRoles() {
      return !!this.productDetails?.allowedJobRoles?.length;
    },
  },
  methods: {
    addJobRoles() {
      const id = this.$route.params.productId;
      this.$router.push(`/products/${id}/job-roles`);
    },
  },
};
</script>
