<template>
  <div class="bg-white p-2 relative mb-2">
    <div class="max-w-3/5">
      <p class="font-headers text-mdl font-bold text-grey-light mt-1 mb-1">
        Communication
      </p>
      <div class="ml-2">
        <p class="font-normal text-grey-dark-1 text-mdh mb-2">
          Create default messages to be sent out after admins launch the
          assessment. These messages will be mandatory for company admins and
          they cannot be edited by them.
        </p>
        <div class="w-full h-125 bg-blue-light-1 rounded-md flex py-2 px-3">
          <div class="w-full">
            <p class="text-grey-dark-1 text-mdh font-semibold">
              Individual Assessment Communication
            </p>
            <p class="text-grey-light text-sm-2-h font-normal">
              {{ defaultEmails }} Default Email Message{{
                defaultEmails === 1 ? "" : "s"
              }}
            </p>
            <p class="text-grey-light text-sm-2-h font-normal">
              {{ invitationEmails }} Invitation Email Message{{
                invitationEmails === 1 ? "" : "s"
              }}
            </p>
            <p class="text-grey-light text-sm-2-h font-normal">
              {{ additionalEmails }} Additional Email Message{{
                additionalEmails === 1 ? "" : "s"
              }}
            </p>
          </div>
          <div class="flex gap-1 ml-auto items-center">
            <IconButton
              :icon="isReadOnly ? view : edit"
              :onClick="editCommunication"
              class="iconButton border border-grey-border"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import edit from "@/assets/img/icons/edit.svg";
import view from "@/assets/img/icons/view.svg";

import IconButton from "../../../components/IconButton/IconButton.vue";

export default {
  name: "CommunicationCard",
  components: { IconButton },
  data: () => ({ edit, view }),
  computed: {
    ...mapState({
      productDetails: (state) => state.products.productDetails,
    }),
    ...mapGetters({
      isReadOnly: "products/isReadOnly",
    }),
    defaultEmails() {
      return this.productDetails?.communicationSummary?.defaultEmailsCount;
    },
    invitationEmails() {
      return this.productDetails?.communicationSummary?.invitaionEmailsCount;
    },
    additionalEmails() {
      return this.productDetails?.communicationSummary?.additionalEmailsCount;
    },
  },
  methods: {
    ...mapActions({
      resetIntroImage: "products/resetIntroImage",
    }),
    editCommunication() {
      this.resetIntroImage();
      this.$router.push("communication");
    },
  },
};
</script>
