<template>
  <div class="bg-white p-2 relative mb-2">
    <Button v-if="!isReadOnly" :iconLeft="editIcon" text="Edit" type="secondary" @click.native="editDetails" size="medium"
      class="absolute top-2 right-2" />
    <div class="max-w-3/5">
      <p class="font-headers text-mdl font-bold text-grey-light mt-1 mb-2">
        Product Details
      </p>
      <div class="ml-2">
        <div class="flex flex-col justify-between  mb-4">
          <p class="text-sm-2 text-grey-light font-medium mb-1">
            Product Name
          </p>
          <p class="font-normal text-grey-dark-1 text-mdh">
            {{ productDetails.productName }}
          </p>
        </div>
        <div class="flex gap-6.5 mb-4">
          <div class="flex flex-col justify-between">
            <p class="text-sm-2 text-grey-light font-medium mb-1">Type</p>
            <p class="font-normal text-grey-dark-1 text-mdh">
              {{ surveyType }}
            </p>
          </div>
        </div>
        <div class="flex flex-col justify-between mb-4">
          <p class="text-sm-2 text-grey-light font-medium mb-1">Description</p>
          <p class="font-normal text-grey-dark-1 text-mdh">
            {{ productDetails.description }}
          </p>
        </div>
        <div class="flex flex-col justify-between mb-4">
          <p class="text-sm-2 text-grey-light font-medium mb-1">Duration</p>
          <p class="font-normal text-grey-dark-1 text-mdh">
            {{ productDetails.duration }} minutes
          </p>
        </div>
        <div class="flex flex-col justify-between mb-4">
          <p class="text-sm-2 text-grey-light font-medium mb-1">Rules</p>
          <ul class="list-disc ml-2.5">
            <li class="font-normal text-grey-dark-1 text-mdh">
              {{
                productDetails.hasRandomisedQuestions ? "Allow" : "Don't allow"
              }}
              randomization of question
            </li>
            <li class="font-normal text-grey-dark-1 text-mdh">
              {{
                productDetails.allowInMultipleSessions ? "Allow" : "Don't allow"
              }}
              completion in multiple sessions{{ allowInMultipleSessions }}
            </li>
            <li class="font-normal text-grey-dark-1 text-mdh">
              {{ productDetails.allowGoingBack ? "Allow" : "Don't allow" }}
              going back and changing answers
            </li>
          </ul>
        </div>
        <div class="flex flex-col justify-between mb-1">
          <p class="text-sm-2 text-grey-light font-medium mb-1">When to Publish Results for Employees</p>
          <p class="font-normal text-grey-dark-1 text-mdh">
            {{ employeeResultsPermission }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import editIcon from "@/assets/img/icons/edit.svg";

export default {
  name: "ProductDescriptionCard",
  data: () => ({ editIcon }),
  computed: {
    ...mapState({
      productDetails: (state) => state.products.productDetails,
    }),
    ...mapGetters({
      isReadOnly: "products/isReadOnly",
    }),
    surveyType() {
      return this.productDetails?.surveyType === 0
        ? "Individual"
        : "Organizational";
    },
    allowInMultipleSessions() {
      if (this.productDetails?.allowInMultipleSessions) {
        return;
      }
      const hours = this.productDetails.maxTimeAllocation.split(':')[0]
      const minutes = this.productDetails.maxTimeAllocation.split(':')[1]
      const hoursString = hours != '00' ? `${hours?.replace(/^0+/, '')} hours` : '';
      const minutesString = minutes != '00' ? `${minutes?.replace(/^0+/, '')} minutes` : '';
      const timeString = [hoursString, minutesString]
        .filter(x => x)
        .join(' and ')
      return `, ${timeString} time allocation`
    },
    employeeResultsPermission() {
      const resultPermissionLabels = {
        2: "Upon assessment completion",
        4: "After admin publish",
        3: "Upon assessment close date",
        1: "Never",
      };
      return resultPermissionLabels[this.productDetails.resultsPermission] || 'No permissions set';
    }
  },
  methods: {
    editDetails() {
      const id = this.$route.params.productId;
      this.$router.push(`/products/${id}/details`);
    },
  },
};
</script>
