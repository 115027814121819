<template>
  <div class="mb-1">
    <div class="flex flex-row w-full bg-grey-light-3 pl-1 gap-1 py-1 pl-1.5 rounded">
      <div class="flex flex-row items-center justify-center focus:outline-none w-100 ml-1">
        <p class="text-sm-2 text-grey-light mr-0.4">NO.</p>
      </div>
      <div class="flex flex-grow w-1/4 mr-1">
        <button class="flex flex-row items-center focus:outline-none" @click="sort('competency', 1)"
          data-testid="header-1">
          <p class="text-sm-2 text-grey-light mr-0.4">COMPETENCY NAME</p>
          <img src="../../../../assets/img/delta-table/caret.svg" class="transition-all"
            :class="rotationMatrix[1] !== 1 ? 'transform rotate-180' : null" />
        </button>
      </div>
      <div class="flex flex-grow w-2/5">
        <button class="flex flex-row items-center focus:outline-none" @click="sort('subCompetency', 2)"
          data-testid="header-2">
          <p class="text-sm-2 text-grey-light mr-0.4">SUB-COMPETENCY</p>
          <img src="../../../../assets/img/delta-table/caret.svg" class="transition-all"
            :class="rotationMatrix[2] !== 1 ? 'transform rotate-180' : null" />
        </button>
      </div>
      <div class="flex flex-grow w-1/5 p-0.5 justify-center items-center pl-2">
        <button class="flex flex-row items-center focus:outline-none" data-testid="header-1"
          @click="() => sort('status', 3)">
          <p class="text-sm-2 text-grey-light mr-0.4">STATUS</p>
          <img src="../../../../assets/img/delta-table/caret.svg" class="transition-all"
            :class="rotationMatrix[3] !== 1 ? 'transform rotate-180' : null" />
        </button>
      </div>
      <div class="flex flex-grow w-160 ml-auto items-center p-0.5">
        <p class="text-sm-2 text-grey-light mr-0.4">ACTIONS</p>
      </div>
    </div>
    <div>
      <VirtualList style="max-height: 460px; overflow-y: auto" :data-key="'id'" :data-sources="mappedList"
        :data-component="itemComponent" :extra-props="{ editCompetency, removeCompetency }" />
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import VirtualList from "vue-virtual-scroll-list";

import ListItem from "./ListItem.vue";

export default {
  name: "ProductCompetencies",
  components: { VirtualList },
  data: () => ({
    rotationMatrix: [1, 1, 1, 1],
    itemComponent: ListItem,
  }),
  computed: {
    ...mapState({
      productDetails: (state) => state.products.productDetails,
    }),
    mappedList() {
      return this.productDetails.coreCompetencies;
    },
  },
  methods: {
    ...mapActions({
      getCompetencyById: "products/getCompetencyById",
      openModal: "modals/openModal",
    }),
    async editCompetency(id) {
      await this.getCompetencyById(id).then(() =>
        this.$router.push(
          `/products/${this.productDetails.productId}/edit-competency/${id}`
        )
      );
    },
    removeCompetency(id) {
      this.openModal({
        modal: "deleteCompetencyModal",
        props: { id },
      });
    },
    resetRotationMatrix(index) {
      this.rotationMatrix = this.rotationMatrix.map((item, idx) =>
        idx === index ? (item *= -1) : (item = 1)
      );
    },
    sort(key, index) {
      this.resetRotationMatrix(index);
      switch (key) {
        case "competency":
          this.mappedList.sort(
            (a, b) => this.rotationMatrix[index] * a.name.localeCompare(b.name)
          );
          break;
        case "subCompetency":
          const getSubcompetenciesString = (item) => item.subCompetencies.map(x => x.name).join(', ');
          this.mappedList.sort(
            (a, b) => this.rotationMatrix[index] *
              (getSubcompetenciesString(a).localeCompare(getSubcompetenciesString(b)))
          );
          break;
        case "status":
          this.mappedList.sort(
            (a, b) =>
              this.rotationMatrix[index] * (a.active - b.active)
          );
          break;
        default:
          break;
      }
    },
  },
};
</script>
