<template>
  <div class="bg-white p-2 relative mb-2">
    <Button
      v-if="!isReadOnly"
      text="Add Competencies"
      type="primary"
      :iconLeft="add"
      @click.native="addCompetencies"
      size="medium"
      class="absolute top-3 right-2"
    />
    <div class="max-w-3/5">
      <p class="font-headers text-mdl font-bold text-grey-light mt-1 mb-1">
        Core Competencies
      </p>
      <div class="ml-2">
        <p class="font-normal text-grey-dark-1 text-mdh mb-2">
          Competencies are large sets of skills that will be assessed during an
          assessment. They will be scored by showing the average results of each
          sub-competency, which, in turn, corespond to a single question in the
          assessment.
        </p>
      </div>
    </div>
    <div class="ml-2 mr-4.5">
      <p
        v-if="!hasCompetencies"
        class="text-mdlh text-grey-dark-1 font-medium mb-1"
      >
        No competencies added yet.
      </p>
      <div v-else>
        <p class="font-normal text-grey-dark-1 text-mdh mb-3">
          {{ compCount }} Total Core Competencies
        </p>
        <ProductCompetencies />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import add from "@/assets/img/icons/add.svg";

import ProductCompetencies from "./ProductCompetencies";

export default {
  name: "CompetenciesCard",
  components: { ProductCompetencies },
  data: () => ({ add }),
  computed: {
    ...mapState({
      productDetails: (state) => state.products.productDetails,
    }),
    ...mapGetters({
      isReadOnly: "products/isReadOnly",
    }),
    hasCompetencies() {
      return !!this.productDetails?.coreCompetencies?.length;
    },
    compCount() {
      return this.productDetails?.coreCompetencies?.length;
    },
  },
  methods: {
    addCompetencies() {
      const id = this.$route.params.productId;
      this.$router.push(`/products/${id}/competencies`);
    },
  },
};
</script>
