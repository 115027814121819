<template>
  <div class="mb-1">
    <div class="flex flex-row w-full bg-grey-light-3 py-1 pl-1.5 rounded">
      <div class="flex flex-row items-center justify-center focus:outline-none w-75 ml-1 pr-1">
        <p class="text-sm-2 text-grey-light mr-0.4">NO.</p>
      </div>
      <div class="flex flex-grow w-1/4 -ml-0.5">
        <button class="flex flex-row items-center focus:outline-none" data-testid="header-1"
          @click="() => sort('jobrolename', 1)">
          <p class="text-sm-2 text-grey-light mr-0.4">JOB ROLE NAME</p>
          <img src="../../../../assets/img/delta-table/caret.svg" class="transition-all"
            :class="rotationMatrix[1] !== 1 ? 'transform rotate-180' : null" />
        </button>
      </div>
      <div class="flex flex-grow w-1/3 items-center ml-0.5">
        <button class="flex flex-row items-center focus:outline-none" data-testid="header-1"
          @click="() => sort('jobTitleMapping', 2)">
          <p class="text-sm-2 text-grey-light mr-0.4">JOB TITLE MAPPING</p>
          <img src="../../../../assets/img/delta-table/caret.svg" class="transition-all"
            :class="rotationMatrix[2] !== 1 ? 'transform rotate-180' : null" />
        </button>
      </div>
      <div class="flex flex-grow w-1/20 mr-1 justify-center items-center">
        <button class="flex flex-row items-center focus:outline-none" data-testid="header-1"
          @click="() => sort('status', 3)">
          <p class="text-sm-2 text-grey-light mr-0.4">STATUS</p>
          <img src="../../../../assets/img/delta-table/caret.svg" class="transition-all"
            :class="rotationMatrix[3] !== 1 ? 'transform rotate-180' : null" />
        </button>
      </div>
      <div class="flex flex-grow w-1/5 justify-center items-center mr-1">
        <p class="text-sm-2 text-grey-light mr-0.4">BENCHMARKS SET</p>
      </div>
      <div class="flex flex-grow w-100 ml-auto items-center p-0.5">
        <p class="text-sm-2 text-grey-light -ml-0.5">ACTIONS</p>
      </div>
    </div>
    <div>
      <VirtualList style="max-height: 460px; overflow-y: auto" :data-key="'id'" :data-sources="mappedList"
        :data-component="itemComponent" :extra-props="{ editJobRole, removeJobRole }" />
    </div>
  </div>
</template>
<script>
import VirtualList from "vue-virtual-scroll-list";
import { mapActions, mapState } from "vuex";

import ListItem from "./ListItem.vue";

export default {
  name: "ProductJobRoles",
  components: { VirtualList },
  data: () => ({
    itemComponent: ListItem,
    rotationMatrix: [1, 1, 1, 1, 1],
  }),
  computed: {
    ...mapState({
      productDetails: (state) => state.products.productDetails,
      mappedList() {
        return this.productDetails.allowedJobRoles;
      },
    }),
  },
  methods: {
    ...mapActions({
      openModal: "modals/openModal",
      getJobRoleById: "products/getJobRoleById",
    }),
    async editJobRole(id) {
      this.getJobRoleById(id).then(() =>
        this.$router.push(
          `/products/${this.productDetails.productId}/job-role/general-information`
        )
      );
    },
    removeJobRole(id) {
      this.openModal({
        modal: "deleteJobRoleModal",
        props: { id },
      });
    },
    resetRotationMatrix(index) {
      this.rotationMatrix = this.rotationMatrix.map((item, idx) =>
        idx === index ? (item *= -1) : (item = 1)
      );
    },
    sort(key, index) {
      this.resetRotationMatrix(index);
      switch (key) {
        case "number":
          this.mappedList.reverse();
          break;
        case "jobrolename":
          this.mappedList.sort(
            (a, b) => this.rotationMatrix[index] * a.name.localeCompare(b.name)
          );
          break;
        case "jobTitleMapping":
          this.mappedList.sort(
            (a, b) =>
              this.rotationMatrix[index] *
              a.jobTitles.localeCompare(b.jobTitles)
          );
          break;
        case "status":
          this.mappedList.sort(
            (a, b) => this.rotationMatrix[index] * (a.isActive - b.isActive)
          );
          break;
        default:
          break;
      }
    },
  },
};
</script>
