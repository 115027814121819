<template>
  <div>
    <div
      v-if="!hasCompetencies"
      class="mb-2 bg-orange h-3.5 w-full flex gap-1 px-1 items-center rounded"
    >
      <img :src="warning" />
      <p class="font-medium text-sm text-white">
        In order to add organizational targets, you need to create core
        competencies first.
      </p>
    </div>
    <div class="bg-white p-2 relative mb-2">
      <div
        v-if="!hasCompetencies"
        class="absolute inset-0 opacity-50 bg-white z-10"
      />
      <div class="max-w-3/5">
        <p class="font-headers text-mdl font-bold text-grey-light mt-1 mb-1">
          Organizational Benchmarks
        </p>
        <div class="ml-2">
          <p class="font-normal text-grey-dark-1 text-mdh mb-2">
            Benchmarks need to be set for the existing competencies specific to
            each assessment. All competencies need to be marked with
            organizational benchmarks.
          </p>
          <div class="w-full h-100 bg-blue-light-1 rounded-md flex py-2 px-3">
            <div class="w-full">
              <p class="text-grey-dark-1 text-mdh font-semibold">
                Organizational Assessment Benchmarks
              </p>
              <p class="text-grey-light text-sm-2-h font-normal">
                {{ productDetails.targetsCount }} Organizational Benchmarks
              </p>
            </div>
            <div class="flex gap-1 ml-auto items-center">
              <IconButton
                :icon="isReadOnly ? view : edit"
                :onClick="addTargets"
                class="iconButton border border-grey-border"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import edit from "@/assets/img/icons/edit.svg";
import view from "@/assets/img/icons/view.svg";
import warning from "@/assets/img/icons/warningWhite.svg";

import IconButton from "../../../components/IconButton/IconButton.vue";

export default {
  name: "OrgTargetsCard",
  data: () => ({ warning, edit, view }),
  components: { IconButton },
  computed: {
    ...mapState({
      productDetails: (state) => state.products.productDetails,
    }),
    ...mapGetters({
      isReadOnly: "products/isReadOnly",
    }),
    hasCompetencies() {
      return !!this.productDetails?.coreCompetencies?.length;
    },
  },
  methods: {
    ...mapActions({
      getOrgTargets: "products/getOrgTargets",
    }),
    async addTargets() {
      await this.getOrgTargets(this.productDetails.id);
      this.$router.push("organizational-targets");
    },
  },
};
</script>
