<template>
  <div class="relative mb-6">
    <div class="flex">
      <h1 class="text-xl font-headers font-bold text-grey-dark-2 pb-3.5">
        <span class="text-grey-medium-2 font-normal">Products / Drafts / </span>
        {{ productDetails.productName }}
      </h1>
      <div class="flex gap-2 ml-auto">
        <Button class="mr-1" text="Cancel" type="secondary" size="medium" @click.native="cancel" />
        <Button v-if="!isReadOnly" text="Remove draft" type="primary-white" :iconLeft="remove" size="medium"
          @click.native="deleteDraft" class="w-160" />
        <Tooltip v-if="!isReadOnly" :content="cantPublishValidationMessage" position="left"
          :active="!canPublish">
          <Button v-if="!isReadOnly" text="Publish" type="primary" size="medium" :disabled="!canPublish"
            @click.native="publish" />
        </Tooltip>
      </div>
    </div>
    <p class="text-mdlh font-medium text-grey-dark-1 max-w-3/5 mb-2">
      In order to launch a product, first you need to add at least one
      active core-competency and at least one active job role. After that you need to add at
      least one component. Then you’re all set to launch.
    </p>
    <div class="flex flex-row items-end mb-2 justify-between">
      <div class="flex flex-row items-end">
        <Select label="Version" :options="versions" :onSelect="setVersion" :value="currentVersion" :preselectFirst="false"
          class="w-180 mr-2" />
        <Button v-if="isReadOnly" type="primary" size="medium" text="Release Notes" :onClick="openReleaseNotesModal" />
      </div>
      <span class="text-mdlh font-medium text-grey-dark-1 m1-2">
        {{ statusText }}
      </span>
    </div>
    <ProductDescriptionCard />
    <CompetenciesCard />
    <JobeRolesCard v-if="isIndividual" />
    <OrgTargetsCard v-else />
    <SurveyCard />
    <CommunicationCard />
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import remove from "@/assets/img/icons/remove.svg";
import moment from 'moment';
import ProductDescriptionCard from "../components/ProductDescriptionCard.vue";
import CompetenciesCard from "../components/CompetenciesCard.vue";
import JobeRolesCard from "../components/JobeRolesCard.vue";
import SurveyCard from "../components/SurveyCard.vue";
import CommunicationCard from "../components/CommunicationCard.vue";
import OrgTargetsCard from "../components/OrgTargetsCard.vue";
import Select from "../../../components/Select/NewSelect.vue";
import Tooltip from "../../../components/Tooltip/Tooltip.vue";

export default {
  name: "ProductDashboard",
  components: {
    ProductDescriptionCard,
    CompetenciesCard,
    JobeRolesCard,
    SurveyCard,
    CommunicationCard,
    OrgTargetsCard,
    Select,
    Tooltip,
  },
  data: () => ({ remove, currentVersion: null }),
  computed: {
    ...mapState({
      productDetails: (state) => state.products.productDetails,
    }),
    ...mapGetters({
      productVersions: "products/productVersions",
      isReadOnly: "products/isReadOnly",
    }),
    canPublish() {
      return this.productDetails?.canPublish;
    },
    cantPublishValidationMessage() {
      if (this.productDetails?.cantPublishMessage && this.productDetails?.cantPublishMessage !== "")
        return this.productDetails?.cantPublishMessage;
      return "Ensure all requirements are met before publishing your product."
    },
    isIndividual() {
      return this.productDetails?.surveyType === 0;
    },
    productId() {
      return this.$route.params.productId;
    },
    versions() {
      if (this.productVersions)
        return this.productVersions.map((item) => {
          const label = item == this.productDetails.draftVersion ? `${item} - Draft` : item
          return { label: label, id: item }
        });
      return [{ label: "1.0" }];
    },
    statusText() {
      if (this.productDetails.retiredDate) {
        return `Retired from: ${moment(this.productDetails.retiredDate.date).format('MM/DD/YYYY')}`
      }

      if (!this.productDetails.publishDate && !this.productDetails.endDate) {
        return "Draft"
      }

      if (this.productDetails.publishDate && !this.productDetails.endDate) {
        return `Active, Published on: ${moment(this.productDetails.publishDate.date).format('MM/DD/YYYY')}`
      }

      if (this.productDetails.publishDate && this.productDetails.endDate) {
        return `Inactive, Published on: ${moment(this.productDetails.publishDate.date).format('MM/DD/YYYY')}, Ended on: ${moment(this.productDetails.endDate.date).format('MM/DD/YYYY')}`
      }
    }
  },
  methods: {
    ...mapActions({
      getProductById: "products/getProductById",
      openModal: "modals/openModal",
      setProductReleaseNotes: "products/setProductReleaseNotes",
    }),
    cancel() {
      this.$router.push(`/products`);
    },
    deleteDraft() {
      this.openModal({
        modal: "deleteProductModal",
        props: { id: this.productId },
      });
    },
    publish() {
      if (!this.productDetails?.publishDate) {
        this.setProductReleaseNotes("")
      }
      this.openModal({
        modal: "publishProductModal",
      });
    },
    setVersion(value) {
      this.getProductById({
        id: this.productId,
        version: value.id,
      }).then((id) => this.$router.push(`/products/${id}/dashboard`));
    },
    openReleaseNotesModal() {
      this.openModal({
        modal: 'viewReleaseNotesModal'
      })
    },
  },
  async mounted() {
    await this.getProductById({ id: this.productId });
    const version = this.productDetails.currentVersion;
    this.currentVersion = { id: version, label: version };
  },
};
</script>
